.user {
    width: 100%;
    height: 95vh;
    overflow: auto;
    background-color: white;
    border-radius: 0 1rem 1rem 0;
    
    .sectionStyles {
        width: 100%;
        height: 100%;
        background-color: white;
        padding: 1.25rem;
        border-radius: 0 1rem 1rem 0;
        // border: 0.25rem solid #a0c0e8;
        
        h2 {
            font-size: 16px;
            color: black;
            font-weight: 600;
            font-family: Lato !important;
        }
 
        .divStyles {
            background-color: white;
            padding: 1.25rem;
            border-radius: 1rem;
            // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

            h1 {
                font-size: 22px;
                color: black;
                font-weight: 700;
                font-family: Lato !important;
            }

            ul {
                color:#475467;
                overflow: auto;
                width: max-content;
                max-height: 30%;
                padding-top: 20px;
                li {
                    padding-top: 10px;
                }
            }


            .green-text {
                color: #10b981;
            }
              
            .red-text {
            color: red;
            }

            .save-btn, .edit-btn, .close-btn, .status-act-btn, .status-deact-btn {
                background-color: #3b82f6;
                // margin-left: 1.25rem; 
                color: #fff;
                font-weight: 700;
                padding-top: 0.5rem; 
                padding-bottom: 0.5rem;
                padding-left: 2rem;
                padding-right: 2rem;
                border-radius: 0.25rem;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                border-width: 0.125rem; 
                border-color: #3b82f6;
    
                &:hover {
                    background-color: transparent;
                    color: #3b82f6;
                    transition-property: all;
                    transition-duration: 0.3s;     
                }
        }
        .edit-btn, .status-act-btn {
            background-color: #10b981;
            border-color: #10b981;

            &:hover {
                color: #10b981;
            }
        }

        .status-deact-btn {
            background-color: red;
            border-color: red;

            &:hover {
                color: red;
            }
        }
        .close-btn {
            background-color: grey;
            border-color: grey;

            &:hover {
                color: grey;
            }
        }
        .generate-btn {
            background-color: white;
            margin-left: 1.25rem; 
            color: #3b82f6;
            font-weight: 700;
            padding-top: 0.5rem; 
            padding-bottom: 0.5rem;
            padding-left: 2rem;
            padding-right: 2rem;
            border-radius: 0.25rem;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            border-width: 0.125rem; 
            border-color: #3b82f6;

            &:hover {
                background-color: #3b82f6;
                color: white;
                transition-property: all;
                transition-duration: 0.3s;     
            }
        }
    }
    }
    .css-kiill1-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
        color: #344054 !important;
    }
    input:focus {
        outline: none !important;
        border:1px solid #A6E4F2;
        border-radius: 50px !important;
        box-shadow: 0 0 10px #719ECE;
    }
    .css-14ydfr0-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
        outline: none !important;
        border:1px solid #A6E4F2 !important;
        border-radius: 50px !important;
        box-shadow: 0 0 10px #719ECE;
    }
    .css-6hp17o-MuiList-root-MuiMenu-list
    .Mui-focused {
        color:#475467 !important;
    }
    label {
        color:#475467 !important;
    }
    .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
        border-width: 0px !important;
    }
}
