.countryContainer {
  height: 100px;
  overflow-y: scroll;
  text-align: start;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
  border-radius: 0.5rem;
  padding-inline: 1rem;
  cursor: pointer;
  div {
    display: flex;
  }
}

.MuiInputBase-adornedStart {
  background-color: white !important;
}
