main {
    // margin: 1.25rem;
    // padding: 1.25rem;
    margin-left: 30px;
    display: grid;
    gap: 2.5rem;
    align-items: start;

    @media (min-width: 1280px) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        align-items: start;
    }

    .sectionStyles {
        background-color: white;
        padding: 1.25rem;
        border-radius: 1rem;
        border: 0.25rem solid #a0c0e8;
 
        .divStyles {
            background-color: white;
            padding: 1.25rem;
            border-radius: 1rem;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  
            .containerStyles_2 {
                display: flex;
                flex-direction: column;
                justify-content: center;
            
                .articleStyles {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;

                    .containerStyles_3 {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .articleStyles_2 {
                    display: grid;
                    grid-template-columns: repeat(3, 1fr);
                    gap: 1rem;
                        
                    .containerStyles_4 {
                        display: flex;
                        flex-direction: column;
                    }
                }

                .articleStyles_3 {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;

                    .containerStyles_4 {
                        display: flex;
                        flex-direction: column;
                    }

                }

                .articleStyles_4 {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 1rem;
                    margin-top: 1rem;

                    .containerStyles_4 {
                        display: flex;
                        flex-direction: column;
                    }
            
                }

            }  
        }
    }
    .sectionStyles_2 {
        background-color: white;
        padding: 1.25rem;
        border-radius: 1rem;
        border: 0.25rem solid #a0c0e8;

        .print {
            background-color: #3b82f6;
            margin-left: 1.25rem; 
            color: #fff;
            font-weight: 700;
            padding-top: 0.5rem; 
            padding-bottom: 0.5rem;
            padding-left: 2rem;
            padding-right: 2rem;
            border-radius: 0.25rem;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            border-width: 0.125rem; 
            border-color: #3b82f6;

            &:hover {
                background-color: transparent;
                color: #3b82f6;
                transition-property: all;
                transition-duration: 0.3s;     
            }
        }
        .email {
            background-color: #10b981;
            margin-left: 1.25rem; 
            color: #fff;
            font-weight: 700;
            padding-top: 0.5rem; 
            padding-bottom: 0.5rem;
            padding-left: 2rem;
            padding-right: 2rem;
            border-radius: 0.25rem;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
            border-width: 0.125rem; 
            border-color: #10b981;

            &:hover {
                background-color: transparent;
                color: #10b981;
                transition-property: all;
                transition-duration: 0.3s;     
            }
        }       
    }
}