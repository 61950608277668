@font-face {
  font-family: "SF Pro";
  src: url("./assets/fonts/sf-pro-regular.OTF");
}

@import "styles/backoffice/variables.scss";

:root {
  --scroll-width: 0.5rem;
}

.root {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: url("./assets/imgs/background.svg");
  background-size: cover;
  padding: 2vh 0;
  overflow: auto;
}

.backOfficeRoot {
  display: grid;
  min-height: 100vh;
  background-color: white;
  padding: 2vh 0;
}

.App {
  --border-radius: 20px;
  width: 80%;
  min-height: 95vh;
  max-height: 95vh;
  min-width: 300px;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 15px 0px #a0a0a080;
  display: grid;
  transition: border-radius 0.5s;
}

.backOfficeApp {
  display: flex;
  justify-content: center;
}

.termsOfUseApp {
  width: 80%;
  min-height: 95vh;
  max-height: 95vh;
  border-radius: var(--border-radius);
  box-shadow: 0px 0px 25px 0px rgba(0, 142, 175, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  text-align: start;
}

.sendCodeViaButton {
  text-decoration: underline !important;
  color: #15788E !important;
}

body .App *,
body .App ::before,
body .App ::after {
  font-family: Lato !important;
}

body .backOfficeApp *,
body .backOfficeApp ::before,
body .backOfficeApp ::after {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

.spinner {
  display: inline-block;
  width: 80px; /* Size of the spinner */
  height: 80px;
  border: 8px solid rgba(255, 255, 255, 0);
  border-radius: 50%;
  border-top: 8px solid white; /* White color for the spinning part */
  border-image: linear-gradient(to right, white, rgba(255, 255, 255, 0)) 1 round;
  animation: spin 2s linear infinite;
}

.fade-in {
  animation: fadeIn 1s ease-out;
}

// scrool bar

/* width */
::-webkit-scrollbar {
  width: var(--scroll-width);
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: var(--scroll-width);
  background: var(--Gray-200, #eaecf0);
}

/* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: #555;
// }

.downloadAppPageText {
  text-align: center;
  color: #667085;
}

.downloadAppPageWrapper {
  background-color: white;
  border-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
  .downloadAppPageContainer {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    .storesContainer {
      display: flex;
      gap: 2rem;
      margin-top: 2rem;
    }
  }
}

// --------------------------------------------------------------------------------------------------------
// Animations
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@media (max-width: 800px) {
  .root {
    padding: 0 0;
  }
  .App {
    --border-radius: 0;
    width: 100%;
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
    box-shadow: none;
  }
}

// --------------------------------------------------------------------------------------------------------
// CSS RESET
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f2f4f7 inset !important;
}
