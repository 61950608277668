@import "/src/Colors.scss";

// titles
h1 {
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 42px;
}
h1.bold {
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 42px;
}
h2 {
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 36px;
}
h2.bold {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 36px;
}
h3 {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  line-height: 33px;
}
h3.bold {
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}
h4 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
h4.bold {
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 27px;
}
h5.bold {
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.h5BoldPrimary {
  color: $--Primary-600;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}
//body
.Body1 {
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 27px;
}
.Body2 {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.Body3 {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
}
.Body1Medium {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}
.Body2Medium {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.Body3Medium {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
}
.Body1Semibold {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
}
.Body2Semibold {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.Body3Semibold {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}
.Body1SemiboldUnderline {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 27px;
  text-decoration-line: underline;
}
.Body2SemiboldUnderline {
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  text-decoration-line: underline;
}
.Body3SemiboldUnderline {
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  text-decoration-line: underline;
}
// captions
.Caption1 {
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.Caption2 {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.Caption2Semibold {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
}
.Caption3 {
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
}
