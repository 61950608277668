@import "./variables.scss";
@import "./responsibe.scss";

a {
    text-decoration: none;
    color: inherit
}

.main {
    // font-family: "Inter";
    height: 100%;
    width:100%;
    color: $main-color;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
}

.menuContainer {
    // width: 250px;
    padding: 5px 20px;
    border-radius: 2px solid $soft-bg;
}

.contentContainer {
    width: 100%;
    padding: 5px 20px;
}