// Black
$--Black: #1f1f1f;
// White
$--White: #ffffff;
// Gray
$--Gray-25: #fcfcfd;
$--Gray-50: #f9fafb;
$--Gray-100: #f2f4f7;
$--Gray-200: #eaecf0;
$--Gray-300: #d0d5dd;
$--Gray-400: #98a2b3;
$--Gray-500: #667085;
$--Gray-600: #475467;
$--Gray-700: #344054;
$--Gray-800: #1d2939;
$--Gray-900: #101828;
// Primary
$--Primary-25: #EDFAFC;
$--Primary-50: #CAEFF7;
$--Primary-100: #A6E4F2;
$--Primary-200: #83D9EC;
$--Primary-300: #5FCEE7;
$--Primary-400: #3CC3E2;
$--Primary-500: #20B3D5;
$--Primary-600: #099CBE;
$--Primary-700: #007c99;
$--Primary-800: #005b70;
$--Primary-900: #0b3c47;
// Error
$--Error-25: #fffbfa;
$--Error-50: #fef3f2;
$--Error-100: #fee4e2;
$--Error-200: #fecdca;
$--Error-300: #fda29b;
$--Error-400: #f97066;
$--Error-500: #f04438;
$--Error-600: #d92d20;
$--Error-700: #b42318;
$--Error-800: #912018;
$--Error-900: #7a271a;
// Warning
$--Warning-25: #fffcf5;
$--Warning-50: #fffaeb;
$--Warning-100: #fef0c7;
$--Warning-200: #fedf89;
$--Warning-300: #fec84b;
$--Warning-400: #fdb022;
$--Warning-500: #f79009;
$--Warning-600: #dc6803;
$--Warning-700: #b54708;
$--Warning-800: #93370d;
$--Warning-900: #7a2e0e;
// Succsess
$--Succsess-25: #f6fef9;
$--Succsess-50: #ecfdf3;
$--Succsess-100: #d1fadf;
$--Succsess-200: #a6f4c5;
$--Succsess-300: #6ce9a6;
$--Succsess-400: #32d583;
$--Succsess-500: #12b76a;
$--Succsess-600: #039855;
$--Succsess-700: #027a48;
$--Succsess-800: #05603a;
$--Succsess-900: #054f31;
// Blue
$--Blue-25: #f5faff;
$--Blue-50: #eff8ff;
$--Blue-100: #d1e9ff;
$--Blue-200: #b2ddff;
$--Blue-300: #84caff;
$--Blue-400: #53b1fd;
$--Blue-500: #2e90fa;
$--Blue-600: #1570ef;
$--Blue-700: #175cd3;
$--Blue-800: #1849a9;
$--Blue-900: #194185;
// Blue Light
$--Blue-light-25: #f5fbff;
$--Blue-light-50: #f0f9ff;
$--Blue-light-100: #e0f2fe;
$--Blue-light-200: #b9e6fe;
$--Blue-light-300: #7cd4fd;
$--Blue-light-400: #36bffa;
$--Blue-light-500: #0ba5ec;
$--Blue-light-600: #0086c9;
$--Blue-light-700: #026aa2;
$--Blue-light-800: #065986;
$--Blue-light-900: #0b4a6f;
// Blue gray
$--Blue-gray-25: #fcfcfd;
$--Blue-gray-50: #f8f9fc;
$--Blue-gray-100: #eaecf5;
$--Blue-gray-200: #d5d9eb;
$--Blue-gray-300: #afb5d9;
$--Blue-gray-400: #717bbc;
$--Blue-gray-500: #4e5ba6;
$--Blue-gray-600: #3e4784;
$--Blue-gray-700: #363f72;
$--Blue-gray-800: #293056;
$--Blue-gray-900: #101323;
// Indigo
$--Indigo-25: #f5f8ff;
$--Indigo-50: #eef4ff;
$--Indigo-100: #e0eaff;
$--Indigo-200: #c7d7fe;
$--Indigo-300: #a4bcfd;
$--Indigo-400: #8098f9;
$--Indigo-500: #6172f3;
$--Indigo-600: #444ce7;
$--Indigo-700: #3538cd;
$--Indigo-800: #2d31a6;
$--Indigo-900: #2d3282;
// Purple light
$--Purple-light-25: #fcfaff;
$--Purple-light-50: #f9f5ff;
$--Purple-light-100: #f4ebff;
$--Purple-light-200: #e9d7fe;
$--Purple-light-300: #d6bbfb;
$--Purple-light-400: #b692f6;
$--Purple-light-500: #9e77ed;
$--Purple-light-600: #7f56d9;
$--Purple-light-700: #6941c6;
$--Purple-light-800: #53389e;
$--Purple-light-900: #42307d;
// Purple
$--Purple-25: #fafaff;
$--Purple-50: #f4f3ff;
$--Purple-100: #ebe9fe;
$--Purple-200: #d9d6fe;
$--Purple-300: #bdb4fe;
$--Purple-400: #9b8afb;
$--Purple-500: #7a5af8;
$--Purple-600: #6938ef;
$--Purple-700: #5925dc;
$--Purple-800: #4a1fb8;
$--Purple-900: #3e1c96;
// Pink
$--Pink-25: #fef6fb;
$--Pink-50: #fdf2fa;
$--Pink-100: #fce7f6;
$--Pink-200: #fcceee;
$--Pink-300: #faa7e0;
$--Pink-400: #f670c7;
$--Pink-500: #ee46bc;
$--Pink-600: #dd2590;
$--Pink-700: #c11574;
$--Pink-800: #9e165f;
$--Pink-900: #851651;
// Rose
$--Rose-25: #fff5f6;
$--Rose-50: #fff1f3;
$--Rose-100: #ffe4e8;
$--Rose-200: #fecdd6;
$--Rose-300: #fea3b4;
$--Rose-400: #fd6f8e;
$--Rose-500: #f63d68;
$--Rose-600: #e31b54;
$--Rose-700: #c01048;
$--Rose-800: #a11043;
$--Rose-900: #89123e;
// Orange
$--Orange-25: #fffaf5;
$--Orange-50: #fff6ed;
$--Orange-100: #ffead5;
$--Orange-200: #fddcab;
$--Orange-300: #feb273;
$--Orange-400: #fd853a;
$--Orange-500: #fb6514;
$--Orange-600: #ec4a0a;
$--Orange-700: #c4320a;
$--Orange-800: #9c2a10;
$--Orange-900: #7e2410;
