@import "../../../styles/backoffice/variables.scss";


.code {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
    z-index: 100;

    .modal {
        background-color: white;
        display: flex;
        align-items: center;   
        justify-content: center;
        min-width: 26%;
        max-width: 50%;
        border-radius: 40px;
        position: relative;
        box-shadow: 0px 2px 15px 0px #10182814, 0px 10px 24px -4px #10182824;

        h2 {
            font-size: 16px;
            color: black;
            font-weight: 600;
            font-family: Lato !important;
            padding-bottom: 10px;
            // padding-left: 20px;
            // padding-right: 20px;
            margin-top: 10px;
            text-align: center;
            justify-content: center;
        }

        h1 {
            margin-top: 40px;
            margin-bottom: 20px;
            font-weight: bold;
            font-size: 30px;
            color: #12B76A;
            background-color: #F2F4F7;
            border-radius: 40px;
            text-align: center;
            justify-content: center;
        }
        span {
            color: black;
            padding-right: 15px;
            padding-top: 15px;
            font-family: Lato !important;
        }
        .css-1gw4fqm-MuiLinearProgress-root {
            border-radius: 50px;
        }

        p {
            color: black;
            // text-align: center;
        }

        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

        .sectionStyles {
            width: 100%;
            background-color: white;
            padding: 1.25rem;
            border-radius: 2rem;

            .divStyles {
                display: flex;
                flex-direction: column;
                text-align: center;
                background-color: white;
                padding: 1.25rem;
                border-radius: 1rem;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

                h1 {
                    margin: 40px;
                    font-weight: bold;
                    font-size: 30px;
                    color: $soft-color;
                }

                h2 {
                    margin: 40px;
                    font-weight: 600;
                    font-size: 16px;
                    // color: $soft-color;
                }

                // .copy-btn {
                //     background-color: #3b82f6;
                //     // margin-top: 2rem; 
                //     // margin-left: 1.25rem; 
                //     color: #fff;
                //     font-weight: 700;
                //     padding-top: 0.5rem;
                //     padding-bottom: 0.5rem;
                //     padding-left: 2rem;
                //     padding-right: 2rem;
                //     border-radius: 0.25rem;
                //     box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                //     border-width: 0.125rem;
                //     border-color: #3b82f6;
                //     width: 100%;

                //     &:hover {
                //         background-color: transparent;
                //         color: #3b82f6;
                //         transition-property: all;
                //         transition-duration: 0.3s;
                //     }
                // }
            }
        }
    }
}