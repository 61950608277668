body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f1f1 !important;
  padding: 0 0 !important;
}

label {
  margin-bottom: 0.3rem;
  font-size: 1.05rem;
  font-weight: 600;
  color: #112827
}

input, textarea {
  background-color: #F2F4F7;
  padding: 0.5rem;
  border-radius: 20px;
  margin-bottom: 2rem;
  color: #383838;
  padding-left: 1rem;
}

textarea {
  resize: none;
  height: 137px;
}

button {
  user-select: none;
}

.footer li {
  margin: 0.5rem 0.2rem;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: row;
}

.button-item {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
    text-align: center;
  }

  .button-item {
    margin-right: 0;
    margin-bottom: 10px;
  }
}



