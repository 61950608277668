@import "/src/Colors.scss";
@import "../../styles/backoffice/variables.scss";

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dynamicActionButton {
  background-color: #83d9ec !important;
  color: #344054 !important;
  border: none !important;
  padding: 4px 14px 4px 14px !important;
}

.usersArea {
  background-color: #eaecf0;
  width: 40%;
  min-width: 15rem;
  .usersAreaHeader {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 1rem;
    .addUser {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background: #20b3d5;
      padding: 0.25rem;
    }
  }
  .paitentApprovalList {
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #d0d5dd;
    span {
      background: #fd853a;
      border-radius: 50%;
      width: 22px;
      height: 22px;
      color: white;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .searchInputContainer {
    padding: 0.5rem 1rem;
  }
  .chatGroup {
    display: flex;
    align-items: center;
    border-radius: 0;
    padding: 10px 24px 10px 11px;
    margin-left: 0.5rem;
    gap: 0.5rem;
    cursor: pointer;

    .chatGroupInformation {
      width: 100%;
      justify-content: center;
      .userName {
        color: #1d2939;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .chatGroupHeaderWrapper {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 10px;
      }
      .lastMessageWrapper {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 10px;
        .lastMessage {
          color: #667085;
          font-size: 0.875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 1.05rem;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
      }
      .lastOnline {
        color: #98a2b3;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }
  .chatGroup:not(.selected):hover {
    background: $--Gray-300;
    margin-left: 0;
    padding: 10px 24px 10px 19px;
  }
  .chatGroup.selected {
    background: white;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
  }
}

.myUsersText {
  margin-left: 1rem;
  margin-block: 1rem;
}

.roundedIcon {
  border-radius: 50%;
  background-color: #eaecf0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page {
  width: 100%;
  min-height: 95vh;
  max-height: 95vh;
  background: var(--Gray-25, #fcfcfd);
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  overflow: hidden;
}

.multiChatWrapper {
  width: 100%;
  min-height: 95vh;
  max-height: 95vh;
  background-color: #f2f4f7;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  .multiChatTitle {
    background-color: #f2f4f7;
    border-top-right-radius: var(--border-radius);
    height: 5%;
    display: flex;
    align-items: center;
    padding-left: 1rem;
  }
  .avatar {
    $--size: 32px;
    min-width: $--size;
    min-height: $--size;
    max-width: $--size;
    max-height: $--size;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: var(--Primary-50, #caeff7);
    overflow: hidden;
    color: var(--Primary-500, #20b3d5);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    img {
      min-width: $--size;
      min-height: $--size;
      max-width: $--size;
      max-height: $--size;
    }
  }
  .textContent {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
  }
  .chatWindowWrapper {
    .chatAreaContainer {
      .chatArea {
        .chatWrapper {
          .chatInformationArea {
            .playPauseChatBottonContainer {
              margin-top: .5rem;
              width: 40px;
              height: 40px;
              margin-left: 1.5rem;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 50%;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              cursor: pointer;
              .pauseButton {
                width: 24px;
                height: 24px;
              }
            }
          }
        }
      }
    }
    .chatAreaContainer {
      .chatHeaderMessage {
        padding: 0.125rem;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 17px;
      }
    }
  }
  .chatInputWrapper {
    background: white;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: var(--border-radius);
    .chatInputContainer {
      display: flex;
      height: 60%;
      width: 95%;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
      input {
        padding: 0.3rem 0.875rem;
      }
    }
  }
}

.chatWindowWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  height: 95%;
  overflow: auto;
}

.chatWindowWrapper:has(> :nth-child(2)) .chatWrapper {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.chatWindowWrapper:has(> :nth-child(2)) {
  padding: 10px;
}

.chatWindowWrapper:has(> :nth-child(2)) .chatAreaContainer {
  width: 50%;
  height: 100%;
}

.chatWindowWrapper:has(> :nth-child(3)) .chatAreaContainer {
  width: 33.3333%;
  height: 100%;
}

.chatWindowWrapper:has(> :nth-child(4)) .chatAreaContainer {
  width: 33.3333%;
  height: 50%;
}

.multiChatEmptyWrapper {
  display: grid;
  align-items: center;
  width: 100%;
}

.formatedMessage {
  display: flex;
  flex-direction: column;
  gap: .35rem;
}

.dynamicActionsWrapper {
  position: absolute;
  width: 100%;
  bottom: 3rem;
  z-index: 1;
}

.chatWindowWrapper .chatArea {
  border: 1px solid $--Gray-400;
}

.chatWindowWrapper .chatAreaContainer {
  background-color: white;
}

.chatWindowWrapper {
  --border-radius: 0;
}

.chatWindowWrapper .chatArea,
.chatWindowWrapper .chatHeader {
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
}

.chatWindowWrapper .chatHeader {
  padding-inline: 0.5rem;
}

.chatWindowWrapper .chatArea,
.chatWindowWrapper .chatInputWrapper {
  border-bottom-left-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
}

.overflowY {
  height: 75%;
  overflow-y: auto;
  padding-bottom: 1rem;
}

.selectFileMenuButton {
  background-color: #eaecf0;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  position: relative;
  .selectFileMenuWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    bottom: 1rem;
    background-color: white;
    z-index: 1;
    box-shadow: 0px 2px 8px 0px #0000004d;
    border-radius: 8px;
    gap: 10px;
    padding: 16px;
    width: max-content;
    .selectFileMenuItem {
      display: flex;
      align-items: center;
      gap: 6px;
    }
    .selectFileMenuItem:not(:has(.disabled)) {
      cursor: pointer;
    }
  }
}

.chatArea:has(.chatHeaderMessage) {
  .showAvatarButton {
    top: 3.5rem !important;
  }
  .chatAvatarContainer {
    top: 9rem !important;
  }
}

.chatArea {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  .chatHeader {
    height: 100px;
    align-items: start;
    justify-content: space-between;
    border-top-right-radius: var(--border-radius);
    background-color: #eaecf0;
    padding-top: 0.75rem;
    gap: 1rem;
    position: relative;
    .chatHeaderMessageContainer {
      box-shadow: 0px 0px 3px 0px #00000026 inset;
      background: #d0d5dd;
      border-radius: 60px;
      height: 34px;
      padding: 4px;
      gap: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 3rem;
      margin-inline: auto;
      width: fit-content;
      .physicianChatHeaderAiAndPatientButton,
      .physicianChatHeaderMyAiButton {
        color: #667085;
        border-radius: 65px;
        padding: 3px 12px 3px 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 2px;
        cursor: pointer;
      }
      .physicianChatHeaderAiAndPatientButton.physicianChatHeaderSelected {
        color: white;
        background: #7a5af8;
        box-shadow: 0px 0px 2px 0px #00000026;
        padding: 3px 12px 3px 12px;
      }
      .physicianChatHeaderAiAndPatientButton:has(.hasPause) {
        padding: 3px 12px 3px 3px;
      }
      .physicianChatHeaderMyAiButton.physicianChatHeaderSelected {
        color: #099cbe;
        background-color: white;
        box-shadow: 0px 0px 2px 0px #00000026;
        padding: 3px 12px 3px 12px;
      }
    }
    .avatar {
      margin-left: 1rem;
    }
  }
  .chatWrapper {
    background-color: white;
    overflow: hidden;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    position: relative;
    .chatInformationArea {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 3;
      display: flex;
      flex-direction: column;
      width: 100%;
      .chatHeaderMessage {
        margin-top: .5rem;
        margin-inline: auto;
        width: 95%;
        color: white;
        background-color: #7a5af8;
        border-radius: 50px;
        padding: 0.25rem;
        text-align: center;
      }
      .showAvatarButton {
        margin-top: .5rem;
        margin-inline: auto;
        padding-block: 0.5rem;
        height: -moz-fit-content;
        height: fit-content;
        z-index: 2;
        background-color: $--Gray-600;
        color: $--White;
        padding: 5px 10px;
        border-radius: 8px;
        font-size: 15px;
      }
      .playPauseChatBottonContainer {
        margin-top: .5rem;
        width: 56px;
        height: 56px;
        margin-left: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        cursor: pointer;
        .pauseButton {
          width: 24px;
          height: 24px;
        }
      }
      .chatAvatarContainer {
        margin-top: .5rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        border-radius: 14px;
        background: var(--Gray-300, #d0d5dd);
        padding: 4px;
        gap: 4px;
        width: 150px;
        height: 150px;
        height: fit-content;
        margin-inline: auto;
        text-align: center;
        color: #1d2939;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        z-index: 2;
        position: relative;
        .chatAvatar {
          width: 100%;
          height: 100%;
          border-radius: 0.75rem;
        }
        .closeAvatarButton {
          position: absolute;
          top: 0.6rem;
          right: 0.6rem;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 22px;
          height: 22px;
          padding: 8px;
          gap: 6px;
          border-radius: 4px;
          border: 1px solid var(--Gray-500, #667085);
          background: var(--Gray-500, #667085);
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          color: white;
        }
      }
      .chatAvatarContainerExpanded {
        width: 100%;
        text-align: center;
        color: #1d2939;
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 150%;
        z-index: 10;
        .voiceModeButton {
          position: absolute;
          top: 1rem;
          right: 1rem;
          width: 3rem;
          height: 3rem;
          border-radius: 50%;
          min-width: 3rem;
        }
        .chatAvatar {
          width: 100%;
          border-radius: 0.75rem;
        }
        .closeAvatarButton {
          display: none;
        }
      }
      .showAvatarButtonMyAiWrapper {
        margin-top: .5rem;
        width: 100%;
        z-index: 1000;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .chat {
      justify-content: end;
      flex: 1;
      .chatMessagesList {
        padding-block: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        position: absolute;
        overflow: auto;
        inset: 0;
        z-index: 1;
        padding-top: 15%;
        padding-bottom: 8%;
        &[data-pos="bottom"] {
          top: auto;
        }

        .paitentMessage {
          max-width: 100%;
          display: flex;
          flex-direction: row-reverse;
          align-self: flex-end;
          gap: 0.5rem;
          padding-right: 0.5rem;
          .chatMessageImageContainer {
            background-color: $--Primary-600;
            padding: 10px 8px 10px 8px;
            // box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
            border-radius: 8px 0px 8px 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            img {
              width: 218px;
              height: 179px;
              border-radius: 2px;
            }
          }
          .chatMessageContentWrapper {
            max-width: 70%;
            display: flex;
            flex-direction: column;
            align-items: end;
            .chatMessageContent {
              padding: 10px 14px 10px 14px;
              border-radius: 8px 0px 8px 8px;
              gap: 8px;
              background: $--Primary-600;
              color: white;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              // box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
              .readMore {
                color: white;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5rem;
                text-decoration-line: underline;
              }
            }
          }
          .chatMessageFileContent {
            padding: 10px 14px 10px 14px;
            display: flex;
            border-radius: 8px 0px 8px 8px;
            gap: 8px;
            width: fit-content;
            background: $--Primary-500;
            color: white;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            // box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
            .readMore {
              color: white;
              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: 1.5rem;
              text-decoration-line: underline;
            }
          }
          .chatMessageTimeStamp {
            color: #98a2b3;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.125rem;
            text-align: end;
          }
          .fileNameText {
            color: $--Gray-700;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;
          }
          .fileSizeText {
            color: white;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.125rem;
          }
        }

        .botMessage {
          max-width: 100%;
          display: flex;
          gap: 0.5rem;
          align-items: start;
          padding-left: 0.5rem;
          .chatMessageImageContainer {
            padding: 10px 8px 10px 8px;
            background-color: #f2f4f7;
            // box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
            border-radius: 0px 8px 8px 8px;
            display: flex;
            flex-direction: column;
            gap: 8px;
            img {
              width: 218px;
              height: 179px;
              border-radius: 2px;
            }
          }
          .chatMessageContentWrapper {
            max-width: 70%;
            display: flex;
            flex-direction: column;
            align-items: start;
            .chatMessageContent {
              padding: 10px 14px 10px 14px;
              border-radius: 0px 8px 8px 8px;
              gap: 8px;
              width: fit-content;
              background-color: #f2f4f7;
              color: black;
              font-size: 16px;
              font-weight: 400;
              line-height: 24px;
              // box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
              .readMore {
                color: black;
                font-size: 1rem;
                font-style: normal;
                font-weight: 600;
                line-height: 1.5rem;
                text-decoration-line: underline;
              }
            }
          }
          .chatMessageFileContent {
            display: flex;
            padding: 10px 14px 10px 14px;
            border-radius: 0px 8px 8px 8px;
            gap: 8px;
            width: fit-content;
            background-color: #f2f4f7;
            color: black;
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            // box-shadow: 0px 1px 2px 0px #1018280f, 0px 1px 3px 0px #1018281a;
            .readMore {
              color: black;
              font-size: 1rem;
              font-style: normal;
              font-weight: 600;
              line-height: 1.5rem;
              text-decoration-line: underline;
            }
          }
          .chatMessageTimeStamp {
            color: #98a2b3;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.125rem;
          }
          .fileNameText {
            color: #344054;
            font-size: 1rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.5rem;
          }
          .fileSizeText {
            color: #667085;
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.125rem;
          }
        }

        li:has(.seperator) {
          max-width: 100%;
          width: 100%;
          div {
            display: flex;
            align-items: center;
            width: 100%;
            div {
              height: 0.0625rem;
              background: var(--Gray-300, #d0d5dd);
              margin-inline: 1rem;
            }
            .seperator {
              width: fit-content;
              color: #1b96b1;
              text-align: center;
            }
          }
        }
      }
    }
  }

  // .chat::before {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: calc(100% - var(--scroll-width));
  //   height: 40%;
  //   background: linear-gradient(180deg, #ffffff 45%, rgba(255, 255, 255, 0) 97.5%);
  //   z-index: 2;
  // }

  // .chat:has(.chatAvatar)::before {
  //   height: 40%;
  // }

  // .chat:not(:has(.chatAvatar))::before {
  //   height: 20%;
  // }

  .illustration {
    flex: 1;
    gap: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;

    .illustrationTitle {
      font-size: 18px;
      font-weight: 600;
      line-height: 27px;
    }

    .illustrationParagrath {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    button {
      background: #20b3d5;
      color: white;
      display: flex;
      align-items: center;
      padding: 10px 16px 10px 16px;
      border-radius: 8px;
      gap: 8px;
    }
  }
  .chatInputWrapper {
    background: white;
    width: 100%;
    align-items: center;
    justify-content: center;
    border-bottom-right-radius: var(--border-radius);
    .sendMessageButtonWrapper {
      width: 50px;
      aspect-ratio: 1/1;
      position: relative;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      .micAnimationOne {
        position: absolute;
        width: 100%;
        aspect-ratio: 1/1;
        transform: scale(0);
        animation: scaleAndDisapper 3s 1s infinite ease-in-out;
        border: 5px solid $--Primary-300;
        border-radius: 50px;
        z-index: 1;
        pointer-events: none;
      }
      .micAnimationTwo {
        position: absolute;
        width: 100%;
        aspect-ratio: 1/1;
        transform: scale(0);
        animation: scaleAndDisapper 3s infinite ease-in-out;
        border: 5px solid $--Primary-100;
        border-radius: 50px;
        z-index: 1;
        pointer-events: none;
      }
      .sendMessageButtonContainer {
        position: absolute;
        background: #20b3d5;
        color: white;
        height: 36px;
        width: 36px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        z-index: 2;
      }
      .sendMessageButtonContainer:has(.disabled) {
        background: #a6e4f2;
      }
    }
    .chatInputContainer {
      display: flex;
      height: 60%;
      width: 95%;
      align-items: center;
      justify-content: center;
      margin-bottom: 12px;
    }
  }
}

.emptyMultiChatText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: .5rem;
}

.emptyMultiChatInnerText {
  display: flex;
}

.chatInputAndMenuContainer {
  display: flex;
  width: 100%;
  align-items: center;
}

.chatInputInnerContainer {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  position: relative;
}

.chatInputWrapper:has(.pleaseContactToReactivate) {
  padding: 1rem 2.5rem 1rem 2.5rem;
  background: #eaecf0;
  justify-content: center;
}

.chatAreaContainerSelected {
  box-shadow: 0px 0px 0px 3px #9aecff;
  border: 1px solid #20b3d5;
  z-index: 1;
}

.chatAreaContainerSelected .chatHeader {
  background-color: $--Primary-500;
}

.chatAreaContainerSelected .chatHeaderTitle {
  color: $--White;
}

.chatAreaContainer {
  display: flex;
  width: 100%;
  background-color: #eaecf0;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  .textContent {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
}

.chatAreaContainer:has(.searchMessagesContainer) {
  .chatHeader {
    border-top-right-radius: 0;
  }
  .chatInputWrapper {
    border-bottom-right-radius: 0;
  }
}

.deleteUserWrapper {
  padding: 1rem;
  gap: 1rem;
  width: 400px;
  height: 40rem;
  border-radius: 30px;
  background-color: $--White;
  box-shadow: 0px 2px 15px 0px rgba(16, 24, 40, 0.08), 0px 10px 24px -4px rgba(16, 24, 40, 0.14);

  //styleName: Body2;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  h1 {
    color: #344054;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 150%;
    padding: 0px 10px;
  }

  h2 {
    color: #98a2b3;
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 150%;
    padding: 0px 10px;
  }

  .deleteUserContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;

    ul {
      list-style: disc;
      padding-left: 2rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }

    p {
      color: #344054;
      font-size: 1rem;
      font-weight: 400;
      line-height: 150%;
    }
  }
}

.cursorHover {
  cursor: pointer;
}

.sideMenu {
  width: 80px;
  background-color: #3cc3e2;
  height: 100%;
  padding: 20px 0px 0px 0px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);

  .sideMenuIcon {
    padding: 16px;
    border-radius: 50%;
    gap: 8px;
    cursor: pointer;
  }

  .sideMenuIcon.selected {
    background: #20b3d5;
  }

  .sideMenuIcon:hover {
    background: $--Primary-300;
  }
}

.sideMenuBO {
  background-color: #98a2b3;

  .sideMenuIcon.selected {
    background-color: #667085;
  }

  .sideMenuIcon:hover {
    background-color: #6670857f;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

.errorMessage {
  color: #f63d68 !important;
}

.logoutButton {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background-color: red;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.testButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: red;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.switchButton {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background-color: red;
  color: white;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}

.conversation {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  color: #1d2939;
  font-size: 1rem;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: white;
  .conversationUserContainer {
    display: flex;
    flex-direction: row-reverse;
    gap: 1rem;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    .voiceIndicatorOn {
      list-style: none;
      z-index: 100;
      display: inline-flex;
      padding: 10px;
      align-items: center;
      gap: 4px;
      width: fit-content;
      align-self: flex-end;
      border-radius: 8px 0px 8px 8px;
      background-color: #1b96b1;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
      div {
        width: 4px;
        height: 4px;
        background-color: $--White;
        border-radius: 50%;
        &:nth-child(1) {
          animation: scaleUpAndDown 1.5s infinite ease-in-out;
        }
        &:nth-child(2) {
          animation: scaleUpAndDown 1.5s 0.2s infinite ease-in-out;
        }
        &:nth-child(3) {
          animation: scaleUpAndDown 1.5s 0.1s infinite ease-in-out;
        }
      }
    }
    .voiceIndicatorOff {
      list-style: none;
      z-index: 100;
      display: inline-flex;
      padding: 10px;
      align-items: center;
      gap: 4px;
      width: fit-content;
      align-self: flex-end;
      border-radius: 8px 0px 8px 8px;
      background-color: #1b96b1;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
      div {
        width: 4px;
        height: 4px;
        background-color: $--White;
        border-radius: 50%;
      }
    }
  }
  .conversationAIContainer {
    display: flex;
    gap: 1rem;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    left: 1rem;
    .voiceIndicatorOn {
      list-style: none;
      z-index: 100;
      display: inline-flex;
      padding: 10px;
      align-items: center;
      gap: 4px;
      width: fit-content;
      align-self: flex-end;
      border-radius: 0px 8px 8px 8px;
      background-color: #1b96b1;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
      div {
        width: 4px;
        height: 4px;
        background-color: $--White;
        border-radius: 50%;
        &:nth-child(1) {
          animation: scaleUpAndDown 1.5s infinite ease-in-out;
        }
        &:nth-child(2) {
          animation: scaleUpAndDown 1.5s 0.2s infinite ease-in-out;
        }
        &:nth-child(3) {
          animation: scaleUpAndDown 1.5s 0.1s infinite ease-in-out;
        }
      }
    }
    .voiceIndicatorOff {
      list-style: none;
      z-index: 100;
      display: inline-flex;
      padding: 10px;
      align-items: center;
      gap: 4px;
      width: fit-content;
      align-self: flex-end;
      border-radius: 0px 8px 8px 8px;
      background-color: #1b96b1;
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
      div {
        width: 4px;
        height: 4px;
        background-color: $--White;
        border-radius: 50%;
      }
    }
  }
}

.logoutButton:hover,
.logoutButton:active,
.switchButton:hover,
.switchButton:active {
  background-color: rgb(255, 43, 43);
}

.fileIconWrapper {
  display: flex;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  border-radius: 1.75rem;
  background: #edfafc;
  .fileIconContainer {
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    border-radius: 1.75rem;
    background: #caeff7;
  }
}

.unReadMessages {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  background: #fd853a;
  width: 22px;
  height: 22px;

  p {
    color: white;
    font-size: 0.75rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.125rem;
  }
}

.avatarWrapper {
  position: relative;
  .avatarOnline {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 12px;
    aspect-ratio: 1/1;
    border-radius: 50%;
    border: 1px solid #eaecf0;
    background-color: #12b76a;
  }
}

.smallAvatar {
  $--size: 36px;
  min-width: $--size;
  min-height: $--size;
  max-width: $--size;
  max-height: $--size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--Primary-50, #caeff7);
  overflow: hidden;
  color: var(--Primary-500, #20b3d5);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  img {
    min-width: $--size;
    min-height: $--size;
    max-width: $--size;
    max-height: $--size;
  }
}

.smallAvatar.empty {
  background: #d0d5dd;
}

.avatar {
  $--size: 48px;
  min-width: $--size;
  min-height: $--size;
  max-width: $--size;
  max-height: $--size;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: var(--Primary-50, #caeff7);
  overflow: hidden;
  color: var(--Primary-500, #20b3d5);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  background-size: cover;
  img {
    min-width: $--size;
    min-height: $--size;
    max-width: $--size;
    max-height: $--size;
  }
}

.avatar.empty {
  background: #d0d5dd;
}

.physcianCodeInput {
  width: 400px;
  padding: 18px;
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 24px;
  border-radius: 30px;
  background: var(--White, #fff);
  box-shadow: 0px 2px 5px 0px #00000012, 0px 9px 9px 0px #0000000f, 0px 20px 12px 0px #0000000a,
    0px 35px 14px 0px #00000003, 0px 55px 15px 0px #00000000;
  position: relative;
  h2 {
    color: var(--Gray-900, #101828);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
  }
  .prescriptionRefrealDialogCloseButton {
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
  }
}

.signatureCanvas {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(5px);
  z-index: 100;
  .signatureCanvasWrapper {
    background-color: white;
    box-shadow: 0px 2px 15px 0px #10182814, 0px 10px 24px -4px #10182824;
    gap: 10px;
    padding: 18px;
    border-radius: 30px;
    width: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    .closeButton {
      position: absolute;
      top: 1rem;
      right: 1rem;
    }
    .signatureCanvasContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border: 1px solid black;
    }
  }
}

.userPlusBlueWrapper {
  background-color: #edfafc;
  width: 48px;
  height: 48px;
  border-radius: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-inline: auto;
  .userPlusBlueContainer {
    background-color: #caeff7;
    width: 36px;
    height: 36px;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.settingsSelectList {
  display: flex;
  gap: 20px;
  margin-bottom: 40px;
  cursor: pointer;
  position: relative;

  li {
    color: var(--Gray-500, #667085);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
  }
  .liSelected {
    color: var(--Primary-600, #1b96b1);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    position: relative;
  }
  .liSelected::after {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 0;
    width: 100%;
    height: 1px;
    z-index: 1;
    background: var(--Gray-200, #1b96b1);
  }
}

.settingsSelectList::after {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 0;
  width: 100%;
  height: 1px;
  background: var(--Gray-200, #eaecf0);
}

.uploadPhotoContainer {
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid var(--Gray-200, #eaecf0);
  background: var(--White, #fff);
}

.uploadPhotoContainer.drop {
  background: $--Primary-300;
}

.pageTitle {
  color: var(--Gray-600, #475467);
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 33px;
}

.personalInformationTextContainer {
  h3 {
    color: var(--Gray-600, #475467);
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
  }
  p {
    color: var(--Gray-600, #475467);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
  }
}

.link {
  color: var(--Primary-700, #15788e);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  text-decoration-line: underline;
  cursor: pointer;
}

.supportEmaillink {
  color: var(--Primary-700, #15788e);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
  text-decoration-line: underline;
}

.newPatient {
  color: #105a6a;
  background-color: #d1fadf;
  border-radius: 1rem;
  padding: 2px 8px 4px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.newPatientBadge {
  display: flex;
  padding: 2px 8px 4px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--Success-100, #d1fadf);
  color: var(--Primary-800, #105a6a);
  text-align: center;

  /* Caption2 semibold */
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 18px */
}

.typingIndicatorWrapper {
  position: absolute;
  bottom: 0.5rem;
  left: 0.5rem;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  z-index: 100;
  .typingIndicator {
    list-style: none;
    z-index: 100;
    display: inline-flex;
    padding: 10px;
    align-items: center;
    gap: 4px;
    width: fit-content;
    align-self: flex-end;
    border-radius: 8px 0px 8px 8px;
    background: $--Gray-100;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.1);
    div {
      width: 4px;
      height: 4px;
      background-color: $--Gray-500;
      border-radius: 50%;
      &:nth-child(1) {
        animation: moveUpAndDown 1.5s infinite ease-in-out;
      }
      &:nth-child(2) {
        animation: moveUpAndDown 1.5s 0.33s infinite ease-in-out;
      }
      &:nth-child(3) {
        animation: moveUpAndDown 1.5s 0.66s infinite ease-in-out;
      }
    }
  }
}

.pauseMessage {
  background-color: #fedf89;
  border-radius: 0.5rem;
  padding: 6px 14px 6px 14px;
  text-align: center;
  margin-inline: auto;
}

.chatGroupsList {
  overflow-y: hidden;
}

.searchMessagesContainer {
  background-color: $--Gray-100;
  border-top-right-radius: var(--border-radius);
  border-bottom-right-radius: var(--border-radius);
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1rem;
  padding: 1rem;
  width: 358px;
  .searchInputContainer {
    width: 100%;
  }
}

.foundMessages {
  width: 20rem;
  li {
    padding: 10px 24px 10px 24px;
    border-bottom: 1px solid $--Gray-200;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    gap: 4px;
    width: 20rem;
    .foundMessageTextContainer {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    .foundMessageTime {
      min-width: 20rem;
      font-size: 13px;
      font-weight: 400;
      line-height: 20px;
    }
  }
}

.searchButton {
  border-radius: 50%;
  background-color: #d0d5dd;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loadingBar {
  width: 100%;
  height: 6px;
  position: relative;
  .loadingBarLoaded {
    height: 100%;
    position: absolute;
    background-color: $--Primary-700;
    border-radius: 50px;
    z-index: 1;
  }
  .loadingBarTotal {
    width: 100%;
    height: 100%;
    position: absolute;
    background-color: $--Primary-200;
    border-radius: 50px;
  }
}

.versionText {
  text-align: end;
}

.colorGrey700 {
  color: $--Gray-700;
}

.invisible {
  display: none;
}

.PrescriptionRefrealTextAreaWrapper {
  display: flex;
  flex-direction: column;
  align-items: start;
  textarea {
    width: 100%;
    margin-bottom: 0;
  }
}

progress {
  height: 6px;
  width: 100%;
}

progress::-webkit-progress-bar {
  background-color: #83d9ec;
  border-radius: 50px;
}

progress::-webkit-progress-value {
  background-color: #15788e;
  border-radius: 50px;
}

@media (max-width: 800px) {
  .page {
    min-height: 100vh;
    max-height: 100vh;
  }
}

@keyframes scaleAndDisapper {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0);
    opacity: 0;
  }
}

@keyframes moveUpAndDown {
  0%,
  50%,
  100% {
    transform: translateY(0);
  }
  25% {
    transform: translateY(-0.5rem);
  }
}

@keyframes scaleUpAndDown {
  0%,
  50%,
  100% {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(3);
  }
}
