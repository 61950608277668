@import "/src/Colors.scss";

.MuiDateCalendar-root {
  background-color: $--White;
  color: $--Black;

  .MuiPickersArrowSwitcher-spacer {
    width: 10px;
  }

  .MuiButtonBase-root {
    color: $--Black;
  }

  .MuiPickersDay-root {
    border-radius: 5px;
    border-bottom: 1px solid $--Gray-300;
  }

  .MuiPickersDay-root.MuiPickersDay-today,
  .MuiPickersDay-root.MuiPickersDay-today:focus {
    border-color: $--Primary-500;
    background-color: $--White;
  }

  .MuiPickersDay-root.Mui-selected,
  .MuiPickersDay-root.Mui-selected:focus {
    color: $--White;
    border-color: $--Primary-500;
    background-color: $--Primary-500;
    border-color: $--Primary-500;
  }

  .MuiDayCalendar-header {
    .MuiTypography-caption {
      font-weight: bold;
    }
  }
}

.MuiPickersYear-root {
  .MuiPickersYear-yearButton {
    border-color: $--White;
    background-color: $--White;
    border-radius: 5px;
  }
  .MuiPickersYear-yearButton:hover {
    background-color: $--Gray-200;
    color: $--Black;
  }
  .MuiPickersYear-yearButton.Mui-selected {
    color: $--White;
  }
  .MuiPickersYear-yearButton.Mui-selected:hover {
    color: $--Black;
  }
}
