.conversations {
    
    height: fit-content;
    width: 100%;
    // overflow: auto;
    background-color: white;
    border-radius: 0 1rem 1rem 0;
    .css-1bmh1kd-MuiPickersLayout-root {
        height: 42vh;
 
        .css-1bmh1kd-MuiPickersLayout-root .MuiPickersLayout-actionBar {
            display: none;
        }
        .css-1psulnz-MuiTypography-root-MuiDatePickerToolbar-title {
            color: tomato;
        }
        .css-31ca4x-MuiPickersFadeTransitionGroup-root {
            color: black;
        }
        .Mui-selected {
            color: unset;
        }

        .css-16b5y55-MuiPickersLayout-contentWrapper {
            padding: 16px;
        }

        // .css-1fnozkz-MuiDataGrid-root .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell {
        //     white-space: pre;
        // }

        .delete {
            display: none;
        }
    }

    .dataTable .dataGrid {
        height: 55vh;
    }
}