@import "/src/Colors.scss";
.rtl {
  direction: rtl;
}
.users {
  width: 100%;
  height: 95vh;
  overflow: auto;
  background-color: white;
  border-radius: 0 1rem 1rem 0;
  .sectionStyles {
    width: 100%;
    height: 100%;
    background-color: white;
    padding: 1.25rem;
    border-radius: 0 1rem 1rem 0;
    // border: 0.25rem solid #a0c0e8;
    .divStyles {
      background-color: white;
      padding: 1.25rem;
      border-radius: 1rem;
      // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      .containerStyles_2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        .info {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
          margin-bottom: 20px;

          h1 {
            font-size: 22px;
            font-weight: 700;
            font-family: Lato !important;
            color: black;
          }

          .add-button {
            background-color: $--Blue-500;
            width: 40;
            color: white;
            font-weight: bold;
            padding: 2px 8px;
            border-radius: rounded;
            box-shadow: shadow;
            border-width: 2;
            border-color: $--Blue-500;
            &:hover {
                background-color: transparent;
                color: $--Blue-500;
            }
            transition: all 300ms;
        }
          }
        }
        .loading-spinner {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 200px;
        }
        
        .loading-spinner::after {
          content: "";
          width: 50px;
          height: 50px;
          border: 5px solid #ccc;
          border-top-color: $--Primary-300;
          border-radius: 50%;
          animation: spin 1s linear infinite;
        }
        
        @keyframes spin {
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
    .dataGrid {
      border: none !important;
    
      .css-qvtrhg-MuiDataGrid-virtualScroller {
        color: #475467;
      }
    
      .css-1ts1rpr-MuiTablePagination-root {
        color: #475467;
      }
    
      .css-1pu2tgn-MuiButtonBase-root-MuiButton-root {
        color: #475467;
      }
    
      .css-poaf6j-MuiInputBase-root-MuiInput-root {
        color: #475467;
        input:focus {
          outline: none !important;
          border:none !important;
          border-radius: none !important;
          box-shadow: none;
        }
      }
    }
  }

