@import "../../../styles/backoffice/variables.scss";

// .add {

    .add-modal {
        background-color: white;
        padding: 20px;
        border-radius: 40px;
        box-shadow: 0px 2px 15px 0px #10182814, 0px 10px 24px -4px #10182824;
        position: relative;

        h1 {
            font-size: 22px;
            color: black;
            font-weight: 700;
            font-family: Lato !important;
            padding-bottom: 40px;
        }

        .close {
            position: absolute;
            top: 10px;
            right: 10px;
            cursor: pointer;
        }

        form {
            display: flex;
            flex-direction: column;
            max-width: 500px;
            gap: 20px;
            justify-content: space-between;

            .button-send {
                background-color: #3b82f6;
                margin-top: 2rem; 
                margin-left: 1.25rem; 
                color: #fff;
                font-weight: 700;
                padding-top: 0.5rem; 
                padding-bottom: 0.5rem;
                padding-left: 2rem;
                padding-right: 2rem;
                border-radius: 0.25rem;
                box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
                border-width: 0.125rem; 
                border-color: #3b82f6;
                width: 100%;
    
                &:hover {
                    background-color: transparent;
                    color: #3b82f6;
                    transition-property: all;
                    transition-duration: 0.3s;     
                }
        }

            label {
                font-size: 14px;

                p {
                    b {
                    cursor: pointer;
                    font-weight: 800;
                    text-decoration: underline;
                    }
                    b:hover {
                        color: whitesmoke;
                    }
                }
            }

            .item {
                // width: 80%;
                display: flex;
                flex-direction: column;
                // gap: 5px;
                // margin-bottom: 20px;
                
                

                input, select {
                    padding: 10px;
                    background-color: transparent;
                    color: black;
                    outline: none;
                    // border: 1px solid $soft-color;
                    // border-radius: 3px;

                }
                .react-datepicker__year-dropdown {
                    max-height: 150px;
                    overflow-y: auto;
                }

                .react-datepicker__year-option {
                    padding: 8px;
                    cursor: pointer;
                    transition: background-color 0.2s;
                    
                    &:hover {
                        background-color: $main-bg
                    }
                }
                    .react-datepicker__year-option--selected_year {
                        font-weight: bold;
                        color: #007bff; 
                    }
                    .react-datepicker__navigation--next {
                        right: 2px;
                        width: 29%;
                        background-color: transparent;
                    }
            }



        }
        form input:focus {
            outline: none !important;
            border:1px solid #A6E4F2;
            border-radius: 50px !important;
            box-shadow: 0 0 10px #719ECE;
        }
        
        .css-1hovy0g-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
            outline: none !important;
            border:1px solid #A6E4F2 !important;
            border-radius: 50px !important;
            box-shadow: 0 0 10px #719ECE;
        }
       
        .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
            border-width: 0px !important;
        }
    }

// }
.css-6hp17o-MuiList-root-MuiMenu-list {
    color:#475467;
}
