@import "../../../styles/backoffice/variables.scss";

.activities {
    width: 100%;
    // height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    // background-color: $soft-bg;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index:1;

    .modal {
        padding: 40px;
        border-radius: 10px;
        background-color: $main-bg;
        box-shadow: 0px 2px 15px 0px #10182814, 0px 10px 24px -4px #10182824;
        position: relative;
        overflow-y: auto;

        h2 {
            margin-bottom: 40px;
            font-weight: bold;
            font-size: 30px;
            color: $soft-color;
        }

        // .close {
        //     position: absolute;
        //     top: 10px;
        //     right: 10px;
        //     cursor: pointer;
        // }
        ul {
            list-style: none;
            margin: 0;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            text-wrap: wrap;
            flex-direction: column;

            h4 {
                color: white;
                text-transform: uppercase;
            }

            p {
                padding-right: 15px;
                padding-bottom: 15px;
            }

            time {
                color: grey;
            }
        }

        .doctor {
            li {
                background-color: $soft-bg;
            }
        }

        .button-close {
            // width: 90%;
            padding: 5px;
            position: absolute;
            border-radius: 50%;
            margin-left: -30px;
            margin-top: -30px;
            color: black;
            background-color: whitesmoke;
            cursor: pointer;
        }

    }

}