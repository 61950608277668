.dataTable {

    .dataGrid {
        background-color: white;
        padding-top: 20px;
    }

    .MuiDataGrid-toolbarContainer {
        flex-direction: row-reverse;
    }

    img {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
    }

    .action {
        gap: 15px;

        img {
            width: 20px;
            height:20px;
            cursor: pointer;
        }
    }
    .data-grid-rtl-demo-1hhvphg-MuiDataGrid-root{
        color: grey
    }
    .data-grid-rtl-demo-1pu2tgn-MuiButtonBase-root-MuiButton-root {
        color: grey
    }
    .data-grid-rtl-demo-1ts1rpr-MuiTablePagination-root {
        color: grey
    }

}